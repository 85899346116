<template>
    <div id="Service">
        <div class="main">
            <div class="section section-title">
                <div class="title" data-aos="fade-up">
                    <h1>服務</h1>
                    <span>Service</span>
                </div>
                <div class="subtitle" data-aos="fade-up" data-aos-delay="300">
                    <p>
                        從情境發想、介面設計與建構到資料分析和模型建置<br />
                        酉是將提供您最完整的服務鏈
                    </p>
                </div>
            </div>
            <div class="section section-chart">
                <div class="line"></div>
                <ul id="chart-wrap" name="chart-wrap">
                    <li
                        v-for="(item, index) in serviceData"
                        :key="'chart-data-' + index"
                        class="chart-item"
                        data-aos="zoom-in"
                        :data-aos-delay="400 + 100 * index"
                        data-aos-anchor="#chart-wrap"
                    >
                        <a @click="scrollToElement('#service' + index)">
                            <i :class="'iconfont icon-' + item.icon"></i>
                        </a>
                        <div>
                            <span
                                v-for="(subitem, subindex) in item.subtitle"
                                :key="'chart-data-title' + subindex"
                            >
                                {{ subitem }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="section section-description">
                <ul>
                    <li
                        v-for="(item, index) in serviceData"
                        :key="'description-data-' + index"
                        :id="'service' + index"
                        :name="'service' + index"
                    >
                        <i
                            :class="'iconfont icon-' + item.icon"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            :data-aos-anchor="'#service' + index"
                        ></i>
                        <div
                            class="description-wrap"
                            data-aos="fade-up"
                            data-aos-delay="500"
                            :data-aos-anchor="'#service' + index"
                        >
                            <span>SERVICE 0{{ index + 1 }}</span>
                            <div class="description-title">
                                <h2 class="title">{{ item.title }}</h2>
                                <h3 class="title">
                                    {{ item.subtitle.join(' ') }}
                                </h3>
                            </div>
                            <p class="description-paragraph">
                                {{ item.paragraph }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <ContactForm></ContactForm>
    </div>
</template>

<script>
import { ContactForm } from '../components'

export default {
    name: 'Service',
    components: { ContactForm },
    setup() {
        const serviceData = [
            {
                icon: 'role',
                title: '使用者研究',
                subtitle: ['USER', 'RESEARCH'],
                paragraph:
                    '應用設計思考（Design thinking），針對系統展開使用者研究，藉由訪談形式深入瞭解使用者需求與痛點，確保進入下一步系統設計時的需求定義高度契合與完整。',
            },
            {
                icon: 'design',
                title: '使用者體驗與介面設計',
                subtitle: ['UI/UX', 'DESIGN'],
                paragraph:
                    '畫出用戶旅程地圖（Cutomer Journey Map, CJM），定義出用戶最流暢的功能體驗，並打造原型（Prototype）來讓使用者能夠給予真實的回饋，透過多次迭代來找到最佳方案。',
            },
            {
                icon: 'code',
                title: '系統開發',
                subtitle: ['SYSTEM', 'PROGRAMMING'],
                paragraph:
                    '從一頁式網站到客製化系統，提供最佳的方案與技術支援，同時提供 RWD 響應式、 API 串接、SEO 優化、GA 分析等服務。系統由最新技術開發而成，兼具高匹配度與最佳性能。',
            },
            {
                icon: 'data',
                title: '數據分析',
                subtitle: ['DATA', 'ANALYSIS'],
                paragraph:
                    '將系統中的互動數據做可視化，讓客戶能夠快速獲得報表與使用狀況，並且能夠定期自動化的輸出報表，讓客戶可以在第一時間透過數據做近一步的商業決策與會報。',
            },
            {
                icon: 'ai',
                title: '人工智慧模型建置',
                subtitle: ['AI', 'MODELING'],
                paragraph:
                    '開發 AI 模型，推薦系統使用者最喜愛的產品，進一步提升網站互動率與使用率，同時也提供多項 AI 服務：物件偵測、圖像分析、語意分析等等服務，讓系統能夠從數據當中挖掘更多洞見。',
            },
        ]
        function scrollToElement(id) {
            const el = document.querySelector(id)
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
            }
        }

        return { serviceData, scrollToElement }
    },
}
</script>

<style lang="scss" scoped>
$major-border: 3px solid $major-dark;

.section-chart {
    ul {
        position: relative;
        margin: 0 auto;

        li {
            position: relative;
            z-index: 50;
            a {
                position: relative;
                display: block;
                width: 90px;
                height: 90px;

                text-decoration: none;
                text-align: center;
                line-height: 90px;

                border: $major-border;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: $shadow-box-2;
                cursor: pointer;

                i.iconfont {
                    background: linear-gradient(
                        -45deg,
                        $major-light,
                        $major-dark
                    );
                    background-clip: text;
                    color: transparent;
                    font-size: 45px;
                }

                &:hover {
                    transition: 0.3s;
                    background-color: $major-dark;
                    box-shadow: inset 0 0 10px white;
                    i {
                        transition: 0.3s;
                        color: #fff;
                        text-shadow: 3px 3px 5px
                            rgba($color: #000000, $alpha: 0.2);
                    }
                }
            }
        }

        span {
            display: block;
            color: $major-dark;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.2em;
            letter-spacing: 0.03em;
        }
    }
}
.section-description {
    li {
        position: relative;
        i.iconfont {
            position: absolute;
            z-index: 10;
            color: $major-dark;
        }
        .description-wrap {
            position: relative;
            z-index: 50;

            padding: 25px 25px 15px;

            background: white;
            box-sizing: border-box;
            box-shadow: -15px 3px 15px rgba($color: #000000, $alpha: 0.2);
            border-left: $major-border;

            span {
                display: block;
                margin-bottom: 1.5em;

                font-weight: 300;
                letter-spacing: 0.05em;
            }
            h2 {
                @include h2Setting($major-dark);
                text-align: left;
            }
            h3 {
                @include h3Setting($major-dark);
                text-align: left;
            }
            p {
                @include pSetting();
                margin-top: 2em;
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .section-chart {
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            max-width: 840px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;

                width: 99%;
                height: 45px;
                border-bottom: $major-border;
            }

            li {
                a {
                    margin: 0 auto 30px;
                }
                span {
                    text-align: center;
                }
            }
        }
    }
    .section-description {
        li {
            margin: 0 auto;
            padding-top: calc(#{$nav-height} + 50px);
            width: 640px;
            i.iconfont {
                left: 0;
                bottom: 20px;
                font-size: 240px;
            }
            .description-wrap {
                margin-left: 25%;
                width: 75%;
            }
        }
    }
    li#service1 {
        right: calc((100% - 640px) * 0.1);
    }
    li#service2 {
        left: calc((100% - 640px) * 0.1);
    }
    li#service3 {
        right: calc((100% - 640px) * 0.15);
    }
    li#service4 {
        left: calc((100% - 640px) * 0.15);
    }
    li#service5 {
        right: calc((100% - 640px) * 0.1);
    }
}

@media screen and (max-width: 639px) {
    .section-chart {
        ul {
            width: fit-content;
            &::after {
                content: '';
                position: absolute;
                height: 80%;
                width: 45px;
                left: 2px;
                top: 50px;
                border-right: 3px solid $major-dark;
            }
            li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                a {
                }
                div {
                    padding-left: 20px;
                    width: 110px;
                }
            }
        }
    }
    .section-description {
        li {
            padding-top: 150px;

            i.iconfont {
                left: -30px;
                top: 60px;
                font-size: 160px;
            }
            .description-wrap {
                margin-left: 10%;
            }
        }
    }
}
</style>
